<template>
  <iframe :src="src" width="100%" id="myiframe"></iframe>
</template>
<script>
import {GetLinkById} from "../api/hr";
export default {
  data () {
    return {
      src: "",
    }
  },
  methods: {
    //iframe高度自定义
    changeFrameHeight () {
      var ifm = document.getElementById("myiframe");
      ifm.height = document.documentElement.clientHeight;
    },
    getLinkIds(lid){
      let para = {id: lid};
      GetLinkById(para).then((res) => {
        this.src = res.data.response;
      });
    }
  },
  watch: {
    $route (to, from) {
      let newurl = this.$route.path.slice(13);
      this.getLinkIds(newurl);
      // let turl = decodeURIComponent(this.$route.path.replace(/^\//, ''));
     
    }
  },
  mounted () {
    this.changeFrameHeight();
    let newurl = this.$route.path.slice(13);
    this.getLinkIds(newurl);
    // let turl = decodeURIComponent(this.$route.path.replace(/^\//, ''));
  }
}
</script>